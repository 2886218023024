.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 200;
    display: flex;
    flex-flow: column nowrap;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, 0.6);
  }
  
  .overlayCentered {
    justify-content: center;
  }
  
  .modal {
    max-width: 50%;
    margin: 40px auto;
    padding: 50px;
    background-color: var(--color-white);
    border-radius: 20px;
    outline: none;
  }
  :global(.en) {
    .icon {
      // position: absolute;
      // top: 30px;
      align-self: flex-end;
      width: 19px;
      height: 19px;
      color: var(--color-mercury);
      cursor: pointer;
      transition: all 0.15s ease-out;
    
      &:hover {
        color: var(--color-dodger-blue);
      }
    }
  }
  
  :global(.ar) {
  .icon {
    position: absolute;
    top: 30px;
    z-index: 1;
    width: 19px;
    height: 19px;
    color: var(--color-mercury);
    cursor: pointer;
    transition: all 0.15s ease-out;
  
    &:hover {
      color: var(--color-dodger-blue);
    }
  }
  }
  
  :global {
    .ReactModal__Body--open {
      overflow: hidden;
    }
  
    .ReactModal__Overlay {
      opacity: 0;
      transition: all 0.3s ease-in-out;
    }
  
    .ReactModal__Overlay--after-open {
      opacity: 1;
    }
  
    .ReactModal__Overlay--before-close {
      opacity: 0;
    }
  
    .ReactModal__Content {
      transform: scale(0);
      transition: all 0.3s ease-in-out;
    }
  
    .ReactModal__Content--after-open {
      transform: scale(1);
    }
  
    .ReactModal__Content--before-close {
      transform: scale(0);
    }
  }
  
  :global(.ar) {
    .icon {
      left: 30px;
    }
  }
  
  :global(.en) {
    .icon {
      right: 30px;
    }
  }
  