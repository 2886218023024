.dropdownToggle {
    font-size: 20px !important;

    &::after {
        display: none !important;
    }
}

.dropdownItem {
    font-size: 20px;

    >.active {
        width: auto;
    }

    &:active {
        width: auto;
        background-color: var(--color-theme-primary);
        border-radius: 5px;
    }

    &:hover {
        width: auto;
        border-radius: 5px;
    }
}

.dropdownToggle {
    color: var(--color-theme-primary) !important;
    font-size: 12px;

    &::after {
        display: none !important;
    }
}

.navLink {
    color: var(--color-black) !important;
    font-size: 20px;
}

.navBarNav {
    color: var(--color-theme-primary);
}



.toggler {
    background-color: white;
}

.changeLanguage {
    font-size: 20px;
    color: var(--color-black);
    text-align: left;

    &:hover {
        color: var(--color-theme-primary);
    }

    &:active {
        color: var(--color-theme-primary);
    }

    &:focus {
        color: var(--color-theme-primary);
    }
}

.searchInput {
    height: fit-content;
}

.searchBtn {
    height: auto;
    font-size: 15px;
}

.logoutBtn {
    cursor: pointer;

    // >* {
    //     fill: var(--color-theme-primary);
    // }
}