.control {
    // position: absolute;
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 30px;
}

.label {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 15px;
    color: black;
}

:global(.ar) {
    .labelRequired {
        &:after {
            content: '*';
            font-size: 16px;
            line-height: 19px;
            color: var(--color-red);
        }
    }

    .error {
        right: 0;
    }
}

:global(.en) {
    .labelRequired {
        &:after {
            content: '*';
            font-size: 16px;
            line-height: 19px;
            color: var(--color-red);
        }
    }

    .control {
        align-items: flex-start;
        // justify-content: space-between;
    }

    .error {
        left: 0;
    }
}

.error {
    top: 100%;
    margin-top: 4px;
    color: var(--color-tamarillo);
    font-size: 12px;
}