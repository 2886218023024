:global(.ar){
    .footer{
        direction: rtl;
    }
}

.footer {
    background-color: var(--color-theme-footer);
    // position: absolute;
    // height: 18rem;
    // bottom: 0;
    width: 100%;
    border-top: 1px solid var(--color-theme-primary);
    // flex-wrap: wrap;
}

.inner {
    display: flex;
    justify-content: space-around;
}