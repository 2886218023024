.toast {
    position: relative;
    max-width: 430px;
    background-color: var(--color-red);
    border-radius: 8px;
    cursor: pointer;
}

.inner {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: relative;
    justify-content: center;
}

.main {
    flex: 1;
    margin: 0 48px 0 48px;
}

.title {
    margin-bottom: 0 !important;
}

.text {
    padding: 20px 0;
    color: var(--color-white);
    margin-bottom: 0 !important;
}

.success {
    background-color: green;
}

.error {
    background-color: var(--color-red);
}

.info {
    background-color: var(--color-theme-primary);
}