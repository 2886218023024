.container {
    border: solid 1px var(--color-table-border);
    max-width: 800px;
    display: flex;
    flex-direction: column;
}

.inner {
    display: flex;
}

.headers {
    width: 50%;
    background-color: #F5F5F5;
    border-right: solid 1px var(--color-table-border);
    border-bottom: solid 1px var(--color-table-border);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1.5rem;

    >p {
        font-weight: 600;
    }
}

.data {
    width: 50%;
    border-left: solid 1px var(--color-table-border);
    border-bottom: solid 1px var(--color-table-border);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}