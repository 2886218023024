.breadcrumbs {
    display: flex;
    flex-flow: row-reverse wrap;
    justify-content: flex-end;
    padding: 20px;
    font-size: 12px;
    padding-bottom: 0;
}

.route {
    display: flex;
    // flex-flow: row wrap;
    align-items: center;
    & > p:first-child {
        font-weight: 600;
        color: var(--color-black) !important;
    }
}

.routeText {
    font-size: 13px;
    line-height: 24px;
    color: var(--color-theme-primary);
    margin-bottom: 0;
}

.separatorIcon {
    color: var(--color-black);
    font-weight: 700;
    margin-bottom: 0;
}

.homeRoute {
    display: flex;
    // flex-flow: row wrap;
    align-items: center;
    cursor: pointer;
    color: var(--color-theme-primary);


    &:hover {
        .homeRouteText {
            color: var(--color-theme-primary);
        }

        .homeRouteIcon {
            color: var(--color-theme-primary);
        }
    }
}

.homeRouteText {
    font-size: 12px;
    line-height: 24px;
    transition: all .15s ease-out;
    color: var(--color-theme-primary);
    margin-bottom: 0;
    cursor: pointer;
}

.homeRouteIcon {
    // margin-left: 20px;
    width: 16px;
    height: 16px;
    color: var(--color-theme-primary);
}

:global(.ar) {
    .route {
        flex-flow: row wrap;
    }

    .routeIcon {
        margin-left: 10px;
    }

    .homeRoute {
        flex-flow: row wrap;
    }

    .homeRouteIcon {
        margin-left: 20px;
    }

    .separatorIcon {
        transform: rotate(0deg);
    }
}

:global(.en) {
    .route {
        flex-flow: row-reverse wrap;
    }

    .routeIcon {
        margin-right: 10px;
    }

    .homeRoute {
        flex-flow: row-reverse wrap;
    }

    .homeRouteIcon {
        margin-right: 20px;
        transform: rotate(180deg);
    }

    .separatorIcon {
        transform: rotate(-180deg);
    }
}

@media all and (min-width: 960px) {
    .breadcrumbs {
        // flex-flow: row wrap;
        align-items: center;
        // padding-right: 10px;
    }

    .separatorIcon {
        // margin: 0 20px;
        transform: none;
    }


    :global(.ar) {
        .breadcrumbs {
            flex-flow: row wrap;
            padding-right: 10px;
        }
    }

    :global(.en) {
        .breadcrumbs {
            flex-flow: row-reverse wrap;
            padding-left: 10px;
        }
    }
}

.pointer {
    cursor: pointer;
}

:global(.ar) {
    .breadcrumbs {
        flex-flow: row-reverse wrap;
        padding-right: 10px;
    }
    .route{
        direction: ltr;
    }
}


@media (max-width: 767.98px) {
    .breadcrumbs {
        font-size: 10px;
    }
}