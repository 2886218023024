.main {
    padding-left: 100px;
    padding-right: 100px;
}

.sectionHeader {
    margin-top: 70px;
    display: flex;
    justify-content: space-between;
}

.invoiceCount {
    display: flex;
    font-weight: 100;
    font-size: 12px;
}

.title {
    font-weight: 600;
}