.container {
    border: solid 1px var(--color-table-border);
    // border-radius: 10px;
}

table > thead {
    background-color: #F5F5F5 !important;

    &>tr > th > div {
        justify-content: center;
    }
}

table > tbody > tr > td {
    text-align: center !important;
    font-weight: 600;
    vertical-align: middle;
    line-height: 50px;
}

.actionButtons {
    cursor: pointer;
}
