.numOfOtpsInput {
    border: solid 1px #CCCCCC;
    border-radius: 0px;
    border-right: none;
    background-color: white;
    border-right: solid 1px #CCCCCC;
    line-height: 2px;
}

.otp {
    font-size: 14px;
    color: var(--color-black);
    font-weight: 600;
    border: solid 1px #CCCCCC;
    border-radius: 0;
    text-align: center;
    border-left: 0;
    background-color: white;
}

.title {
    color: var(--color-gray);
    font-size: 14px;
    text-align: center !important;
    font-weight: 600;
    margin-bottom: 50px;
}


.button {
    background-color: var(--color-theme-primary);
    // width: 205px;
    font-size: 14px;
    border-radius: 0;
    height: 36px;
}

.container {
    max-width: fit-content;
    justify-content: center;
}

.separator {
    border-right: solid 1px;
}

.inputs {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: white;
    direction: ltr;
}

.buttonContainer {
    display: flex;
    justify-content: center;

}


@media (max-width: 991px) {
        .container {
            width: 100%;
        }
}