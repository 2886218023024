.acknowledgement {
    font-weight: 600;
    margin-bottom: 20px;
}

.successAck {
    font-weight: 600;
    margin-bottom: 40px;
}

.inputs {
    display: flex;
    align-items: center;
    justify-content: space-around;
    &>input {
        height: 40px;
        background-color: white;
        border: solid 1px #EAEAEA;
    }
    margin-bottom: 20px;
}