.container {
    display: flex;
    margin-top: 30px;
}

.greenLine {
    border: 1px solid var(--color-theme-primary)
}

.grayLine {
    border: 1px solid var(--color-gray)
}