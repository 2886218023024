.container {
    padding: 10px;
}

.box {
    background-color: white;
    padding: 15px;
    margin-top: 20px;
}

.question {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 10px;
}

.radioInner {
    &>label {
        font-size: 13px;
        font-weight: 600;
        margin-left: 10px;
        margin-right: 10px;
    }

    &>input {
        accent-color: var(--color-theme-primary);
    }
}

.headers {
    &>p {
        width: 20%;
        font-weight: 600;
        margin-right: 20px;
        margin-left: 20px;
    }

    margin-bottom: 30px;
    margin-top: 30px;
}

.purchaseHeaders {
    &>p {
        width: 15%;
        font-weight: 600;
        margin-right: 20px;
        margin-left: 20px;
    }

    margin-bottom: 30px;
    margin-top: 30px;
}

.salesInputs {
    margin-bottom: 20px;

    &>p {
        width: 20%;
        margin-bottom: 40px;
    }

}

.input {
    height: 40px;
    border-radius: 0 !important;
    font-size: 15px;

    &>input {
        height: 40px;
        border-radius: 0 !important;
        font-size: 15px;
    }
}


.purchasesInput {
    margin-bottom: 20px;

    &>p {
        width: 15%;
    }

    &>input {
        width: 15%;
        height: 40px;
        margin-right: 20px;
        margin-left: 20px;
        border-radius: 0 !important;
    }

    &>select {
        width: 15%;
        height: 40px;
        margin-right: 20px;
        margin-left: 20px;
        border-radius: 0 !important;
    }
}

.placeholderInput {
    &:disabled {
        background-color: white;
        border: none
    }

    border: none;
}

.notesLabel {
    width: 15%;
}

.notesInput {
    height: 40px;
    width: 70%;
}

.cancelLink {
    text-decoration: underline;
    color: var(--color-theme-primary);
    cursor: pointer;
    font-size: 15px;

    &:hover {
        color: var(--color-theme-primary);
    }
}

.continueBtn {
    border-radius: 0;
    font-size: 15px;
}

.actionsContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
}

.boldHeader {
    font-weight: 600;
}