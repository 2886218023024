.header {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    font-size: 20px;

    >p {
        font-weight: 600;

    }
}

.statistics {
    display: flex;
    > p {
        font-weight: 600;
        font-size: 18px;
    } 
}

:global(.ar){
    direction: rtl;
}