.box {
    padding: 15px;
    background-color: white;
    margin-top: 20px;
}

.title {
    font-weight: 600;
}

.notes {
    &>a {
        color: var(--color-theme-primary);
    }
}

.checkbox {
    margin-right: 10px;
}

.cancelLink {
    text-decoration: underline;
    color: var(--color-theme-primary);
    cursor: pointer;
    font-size: 15px;
    &:hover {
        color: var(--color-theme-primary);
    }
}
.continueBtn {
    border-radius: 0;
    font-size: 15px;
}

.actionsContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
}