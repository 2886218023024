.container {
    // height: 100%;
    display: grid;
    flex-direction: column;
}

.chart {
    align-self: flex-end;
    position: relative;
}

.title {
    text-align: center !important;
    font-weight: 600;
    font-size: 17px;
}



@media (max-width: 767.98px) {
    .chart {
        width: 30vh;
    }
}