.container {
    padding: 50px
}

.iconContainer {
    display: flex;
    align-items: center;
    cursor: pointer;

    &>h6 {
        color: var(--color-theme-primary);
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 0;
    }
}

.actionButtons {
    display: flex;
    justify-content: flex-end;
}

.saveIcon {
    border-right: solid 1px #AFAFAF;
    padding-right: 0.5rem;
}

.field {
    height: 50px;
    border-radius: 0;

    &>input {
        height: 50px;
        font-size: 15px;
    }
}

.label {
    font-size: 15px;
}

.buttonsContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 80px;

    &>button {
        border-radius: 0;
        font-size: 18px;
        font-weight: 550;
        border-width: 1.5px;
    }
}

.addItemDiv {
    display: flex;
    justify-content: flex-end;

    &>button {
        border-radius: 0;
    }
}

.form-container {
    background: white;
    padding: 40px;
}

.section-title {
    margin-bottom: 30px;
    font-size: 22px;
    font-weight: 700;
}

.secondary-title {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 500;
}

.diligence-desc {
    font-size: 15px;
    margin-bottom: 30px;
}

.submitted {
    font-size: 30px;
    font-family: sans-serif;
    margin-bottom: 25px;
}

.approval-status-container {
    display: flex;
    justify-content: space-evenly;
}

.approval-status-approved {
    color: var(--color-success);
    font-weight: 600;
}

.approval-status-pending {
    color: var(--color-pending);
    font-weight: 600;
}

.submission-modal {
    padding: 50px;
}

.onboarding-btn-container {
    display: flex;
    justify-content: center;
}

.initial-text-container {
    background: white;
    padding: 40px;
    font-size: 15px;
}

.due-diligence {
    background: #f5f5f5;
    margin-top: 70px;
    padding: 30px;
}