.icon {
    vertical-align: top
}

.info {
    width: 15px;
    vertical-align: middle;
}

.info:first-child {
    fill: var(--color-theme-primary) !important;
}