.container {
    table {
        text-align: center;
        margin: auto;
        width: 100%;

        &>tbody>tr>td {
            font-weight: 100;
            color: var(--color-table-data-font);
        }

        &>thead {
            background-color: #DBDBDB !important;
        }

        &>thead>tr>th {
            background-color: #DBDBDB !important;
        }

        &>thead>tr>th>div>span {
            font-weight: 600;
        }
    }


}

:global(.ar) {
    .container {

        table {
            direction: rtl;
        }
    }
}

.continue {
    border-radius: 0;
    font-size: 12px;
    width: 75%;
}