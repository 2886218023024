.title {
    color: var(--color-white);
    font-weight: 600;
    font-size: 18px;
}

.links {
    padding-left: 0 !important;
    font-size: 15px;

    >li>a {
        color: white !important;
        text-decoration: none;
    }
}

.greaterThan {
    font-weight: 700;
}

:global(.ar) {
    .subscribeBtn {
        border-radius: 5px 0 0 5px;
    }
    .search {
        border-radius: 0 5px 5px 0;
    }
}

.subscribeBtn {
    height: 35px !important;
    border-radius: 0 !important;
    font-size: 12px;
    background-color: var(--color-theme-primary);
    padding-right: 26px;
    padding-left: 26px;
}
.search {
    height: 35px;
    border-radius: 0;
    border: solid 1px RGBA(255,255,255,0.33 );
    background-color: white;
    font-size: 13px;
}