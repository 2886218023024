.approval-status-approved {
    color: var(--color-success);
    font-weight: 600;
}
.approval-status-pending {
    color: var(--color-pending);
    font-weight: 600;
}
.approval-status-pending-cert {
    color: blue;
    font-weight: 600;
}