.container {
    padding: 50px
}

.iconContainer {
    display: flex;
    align-items: center;
    cursor: pointer;

    &>h6 {
        color: var(--color-theme-primary);
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 0;
    }
}

.actionButtons {
    display: flex;
    justify-content: flex-end;
}

.saveIcon {
    border-right: solid 1px #AFAFAF;
    padding-right: 0.5rem;
}

.field {
    height: 50px;
    border-radius: 0;

    &>input {
        height: 50px;
    }
}

.label {
    font-size: 15px;
}

.buttonsContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;

    &> button {
        border-radius: 0;
        font-size: 18px;
        font-weight: 550;
        border-width: 1.5px;
    }
}

.addItemDiv {
    display: flex;
    justify-content: flex-end;
    &> button{
        border-radius: 0;
    }
}