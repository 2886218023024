.radioInner {
    &>label {
        font-size: 13px;
        font-weight: 600;
        margin-left: 10px;
        margin-right: 10px;
    }

    &>input {
        accent-color: var(--color-theme-primary);
    }
}

.container {
    padding: 10px;
}

.box {
    background-color: white;
    padding: 15px;
}

.title {
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.continueBtn {
    border-radius: 0;
}