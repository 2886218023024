.container {
    background-color: #F5F5F5;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
}

.hovered {
    background-color: #93BC45;
}

@media (max-width: 767.98px) {
    .container {
        width: 70%;
    }
}

.aspect-ratio1 {
    aspect-ratio: 1;
}
.aspect-ratio1_4 {
    aspect-ratio: 1.4;
}