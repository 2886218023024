.container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}

.inner {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.title {
    font-size: 20px;
    color: var(--color-black);
    font-weight: 600;
}

.buttonContainer {
    display: flex;
    justify-content: center;
}

.button {
    background-color: var(--color-theme-primary);
    width: 205px;
    font-size: 14px;
    margin-top: 40px;
}

.wrapper {
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loader {
    justify-content: center;
}