.container {
    padding: 25px;
    background-color: white;
}

.title {
    font-weight: 600;
    margin-bottom: 10px;
}

.declarationContainer {
    display: flex;
    margin-top: 25px;
}

.link {
    color: var(--color-theme-primary);
    &:hover {
        color: var(--color-theme-primary);
    }
}

.checkbox {
    accent-color: var(--color-theme-primary)
}

.actionsContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.cancelLink {
    text-decoration: underline;
    color: var(--color-theme-primary);
    cursor: pointer;
    font-size: 15px;
    &:hover {
        color: var(--color-theme-primary);
    }
}

.continueBtn {
    border-radius: 0;
    font-size: 15px;
}