$inputsHeight: 45px;
$inputsFontSize: 15px;

.searchInput {
    &>input {
        height: $inputsHeight;
        font-size: $inputsFontSize;
    }
}

.field {
    height: $inputsHeight;
    font-size: $inputsFontSize;

}

.datepicker {
    &>input {
        height: $inputsHeight;
        font-size: $inputsFontSize;

        &::placeholder {
            color: --color-theme-inner-border;
        }
    }

}

.dateFrom {
    &>input {
        border-radius: 0;
    }
}

.dateTo {
    &>input {
        border-radius: 0;
    }
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;

}

.resetButton {
    // background-color: var(--color-theme-danger);
    // &:hover {
    //     background-color: #cb0303 !important;
    // }
    border-radius: 0;
    height: $inputsHeight;
}

.filterBtn {
    border-radius: 0;
    height: $inputsHeight;
}

.label {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 15px;
    color: var(--color-black);
}

:global(.ar) {
    .container {
        direction: rtl;
    }

    .dateFrom {
        &>input {
            border-radius: 0;
        }
    }

    .dateTo {
        &>input {
            border-radius: 0;
        }
    }
}


@media screen and (min-width: 991px) and (max-width: 1200px) {
    .container {
        &>form {
            &>div {
                &>div {
                    &>p {

                        font-size: 15px;
                    }
                    &>div {
                        &>p {
                            font-size: 15px;
                        }
                    }
                }
            }
            &input {
                font-size: 15px;
            }
        }
    }
}