.container {
    border: solid 1px var(--color-table-border);
    max-width: 800px;
    display: flex;
    flex-direction: column;
}

.inner {
    display: flex;
}

.headers {
    width: 50%;
    background-color: var(--color-table-head-background);
    border-right: solid 1px var(--color-table-border);
    border-bottom: solid 1px var(--color-table-border);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 3rem;
    padding-bottom: 3rem;

    >p {
        font-weight: 600;
    }
}


.data {
    width: 50%;
    border-left: solid 1px var(--color-table-border);
    border-bottom: solid 1px var(--color-table-border);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}