@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/23165521968.ttf') format('woff2');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/21798841561.ttf') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/20007011713.ttf') format('woff2');
  font-weight: normal;
  font-style: italic;
}

:global {
  #bef-app {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
  }
  * {
    font-family: 'Open Sans';
    font-weight: normal;
    font-style: normal;
  }
}

li {
  list-style-type: none;
}
