:global {
  :root {
    --color-theme-header: #000;
    --color-theme-footer: #000;
    --color-theme-primary: #2B3960;
    --color-theme-secondary: #465374;
    --color-theme-gray: #F0F0F0;
    --color-theme-background: #FAF9F9;
    --color-theme-inner-border: #9D9D9D;
    --color-theme-border: #DFDFDF;
    --color-theme-disabled: #E8E8E8;
    --color-theme-danger: #BC0404;
    --color-table-head-background: #FAFAFA;
    --color-table-border: #D7D7D7;
    --color-table-data-font: #8C8C8C;
    --color-input-background: #F7F7F7;
    --color-jagged-ice: #c7deec;
    --color-concrete: #f2f2f2;
    --color-mercury: #e2e2e2;
    --color-white: #fff;
    --color-black: #2e3045;
    --color-tamarillo: #a11d18;
    --color-silver: #CCCCCC;
    --color-aqua-spring: #F8FBFD;
    --color-shuttle-gray: #61656E;
    --color-silver-chalice: #ABAAAA;
    --color-red: #FF0101;
    --color-disabled: #A2A2A2;
    --color-gray: #7E7E7E;
    --color-success: #28a745;
    --color-pending: #ffc107;
  }
}