:global(.ar) {
    .header {
        direction: ltr;
    }
}


.header {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    background-color: var(--color-theme-header);
    padding: 0 20px;
    width: 100%;
}

.homeButton {
    border-radius: 0px !important;
    height: auto !important;
    width: auto !important;
    border: solid 1px white !important;
    font-size: 12px !important;
    background-color: var(--color-theme-secondary) !important;

    &:hover {
        background-color: var(--color-theme-primary) !important;
    }

    @media (max-width: 1230px) {
        display: none !important;
    }
}

.logo {
    // width: 75px;
    margin-left: 100px;
    margin-right: 100px;
    cursor: pointer;
}

.name {
    font-size: 13px;
    color: white;
    font-family: 'Open Sans';

    >p {
        font-family: 'Open Sans';
        font-weight: bold;
    }
}

.nameAndLang {
    display: flex;
    // flex: 1;
    justify-content: center;
    align-items: center;

    @media (max-width: 1230px) {
        justify-content: end;
        margin-left: 10px;
        margin-right: 10px;
    }
}

.logoAndHome {
    display: flex;
    // flex: 1;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 1230px) {
        justify-content: flex-start;
    }
}

.navigator {
    @media (max-width: 1230px) {
        display: none !important;
    }
}

.sideBarMenu {
    @media (min-width: 1230px) {
        display: none;
    }

    display: flex;
    justify-content: space-between;
    // :global(.ar) {

    // }
}

.searchIcon {
    @media (max-width: 1230px) {
        display: none !important;
    }
}

.logoutBtn {
    cursor: pointer;

    @media (max-width: 1230px) {
        display: none !important;
    }
}

.changeLanguage {
    @media (max-width: 1230px) {
        display: none !important;
    }
}

.innerDiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}


@media (max-width: 767.98px) {
    .logo {
        margin-left: auto;
        margin-right: auto;
    }
    .nameAndLang {
        justify-content: flex-start;
    }
}