.container {
    display: flex;
    justify-content: center;
}

.homeBtn {
    width: 30%;
    justify-self: center;
    margin-top: 50px;
    min-width: fit-content;
    border-radius: 0;
    background-color: var(--color-theme-primary);
    color: white;
    font-weight: 600;
}

@media(min-width: 1024px) {
    .tile {
        width: 30%;
    }
}

.btnDiv {
    display: flex;
    justify-content: center;
}

.tile {
    align-items: normal;
}

.tiles-wrapper {
    justify-content: center;
}