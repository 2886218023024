.p-datatable .p-sortable-column.p-highlight {
    color: var(--color-theme-primary) !important;
}

/* .p-datatable {
    border-radius: 15px;
} */

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
    color: var(--color-theme-primary) !important;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: var(--color-theme-primary) !important;
}

/* .p-datatable-thead>tr>th:first-child {
    border-radius: 15px 0 0 0;
} */

/* .p-datatable-thead>tr>th:last-child {
    border-radius: 0 15px 0 0;
}

.p-datatable-tbody>tr:last-child >td:first-child {
    border-radius: 0  0 0 15px;
}

.p-datatable-tbody>tr:last-child >td:last-child {
    border-radius: 0 0 15px 0;
}

.p-datatable-wrapper {
    border-radius: 15px;
} */

.p-datatable-loading-icon {
    color: var(--color-theme-primary);
}

.p-component-overlay {
    background-color: transparent;
}

.chart-loader {
    justify-content: center;
}

.p-dialog .p-dialog-content:last-of-type {
    border-radius: 0 !important;
}

.p-selectbutton .p-button.p-highlight, .p-selectbutton .p-button.p-highlight:hover {
    background: var(--color-theme-primary);
    border-color: var(--color-theme-primary)
}

.p-radiobutton .p-radiobutton-box.p-highlight, .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: var(--color-theme-primary);
    background: var(--color-theme-primary);
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: var(--color-theme-primary);
}

.p-button, .p-button:enabled:hover, .p-button:not(button):not(a):not(.p-disabled):hover {
    background: var(--color-theme-primary);
    border-color: var(--color-theme-primary);
}

.p-checkbox .p-checkbox-box.p-highlight, .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background: var(--color-theme-primary);
    border-color: var(--color-theme-primary);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover{
    border-color: var(--color-theme-primary);
}

.p-invalid .p-checkbox-box {
    border: 2px solid red;
}