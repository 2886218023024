.title {
    font-size: 16px;
    font-weight: 600;
    color: var(--color-theme-black);
    text-align: center;
}

.button {
    font-size: 15px;
    background-color: var(--color-theme-primary);
    border-radius: 0px;
    width: 85%;
    // height: 12%;
    font-weight: 600;
}

.disableTitle {
    color: var(--color-disabled);
}

.disabledIcon {
    opacity: 0.5;
}

.hoveredBtn {
    background-color: white !important;
    color: black;
}

.container {
    background-color: #F5F5F5;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
}

.titleDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 33.33%;
    width: 100%;
}

.buttonDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 33.33%;
}

.imageDiv {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height: 33.33%;
    padding-top: 25%;
}

.hoveredContainer {
    background-color: #93BC45 !important;
}