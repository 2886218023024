.layout {
    display: flex;
    flex-flow: column nowrap;
    min-height: 100vh;
}

.inner {
    position: relative;
    flex: 1;
    padding-bottom: 5rem;
    min-height: 50vh;
    background-color: #F5F5F5;
}

.withBanner {
    // background-image: url('../../assets/images/banner.png');
    background-repeat: no-repeat;
    min-height: 50vh;
    padding-right: 25px;
    padding-left: 25px;
    background-size: 2900px 325px;
    // display: grid;
}

@media (max-width: 767.98px) {
    .withBanner {
        padding-right: 0;
        padding-left: 0;
    }
}