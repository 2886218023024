.container {
    table {
        text-align: center;
        margin: auto;
        width: 100%;
        // box-shadow: 0px 0px 10px #00000017;
        // border-radius: 15px;
        // background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;

        &>tbody>tr>td {
            font-weight: 100;
            color: var(--color-table-data-font);
            // text-align: left !important;
        }

        &>thead {
            background-color: #DBDBDB !important;
        }

        &>thead>tr>th {
            background-color: #DBDBDB !important;
        }

        &>thead>tr>th>div>span {
            font-weight: 600;
        }
    }


}

:global(.ar) {
    .container {

        table {
            direction: rtl;
        }
    }
}

.modal-buttons-container {
    justify-content: space-around;
}