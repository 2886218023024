.container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.label {
    justify-self: start;
}

.input {
    height: 40px;
    width: 224px;
    border-radius: 0;
    border: solid #EAEAEA 1px;
}

.inputContainer {
    display: flex;
    justify-content: flex-start;
}