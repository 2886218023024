$inputsHeight: 45px;
$inputsFontSize: 15px;

.searchInput {
    &>input {
        height: $inputsHeight;
        font-size: $inputsFontSize;
        border-radius: 0;
    }
}
.field {
    height: $inputsHeight;
    font-size: $inputsFontSize;
    border-radius: 0;
}

.datepicker {
    &>input {
        height: $inputsHeight;
        font-size: $inputsFontSize;
    }
}

.dateFrom {
    &>input {
        border-radius: 0;
    }
}
.dateTo {
    &>input {
        border-radius: 0;
    }
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &> button {
        border-radius: 0;
        height: $inputsHeight;
    }
}

:global(.ar) {
    .container {
        direction: rtl;
    }
    .dateFrom {
        &>input {
            border-radius: 0;
        }
    }
    .dateTo {
        &>input {
            border-radius:0;
        }
    }
}

.labels {
    font-size: 15px;
}