.copyBtn {
    width: 75%;
    font-size: 14px;
    border-radius: 0;
    background-color: white;
    margin-top: 0;
    font-weight: 600;
}

.otp {
    justify-content: center;
    aspect-ratio: unset;
}

.popover {
    border: 1px solid;
    position: absolute;
    padding: 3px;
    border-radius: 5px;
    background-color: var(--color-theme-primary);
    color: white;
    z-index: 1000000000;
    font-size: 12px;
    opacity: 1;
    transition: opacity 0.5s linear;
}

.hidePopover {
    opacity: 0;
}



@media screen and (min-width: 992px) and (max-width: 1250px) {
    .otp {
        width: 40%;
    }
}


@media (max-width: 991px) {
    .otp {
        width: 70%;
    }
}

@media (max-width: 425px) {
    .otp {
        width: 100%;
    }
}

// @media screen and (max-width: 1299px) and (min-width: 767.98px) {
//     .input {
//         width: 70%;
//     }
// }