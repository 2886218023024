.salesBox, .purchaseBox {
    background: white;
    padding: 20px;
    margin-top: 50px;
}

.salesInputs {
    margin-bottom: 20px;

    &>input {
        // width: 20%;
        height: 40px;
        margin-right: 20px;
        margin-left: 20px;
    }
}

.headers {
    &>p {
        // width: 20%;
        font-weight: 600;
        margin-right: 13px;
        margin-left: 13px;
    }

    margin-bottom: 30px;
    margin-top: 30px;
}

.placeholderInput {
    &:disabled {
        background-color: white;
        border: none
    }
    border: none;
}

.purchaseHeaders {
    &>p {
        width: 15%;
        font-weight: 600;
        margin-right: 20px;
        margin-left: 20px;
    }

    margin-bottom: 30px;
    margin-top: 30px;
}

.purchasesInput {
    margin-bottom: 20px;

    &>p {
        width: 15%;
    }

    &>input {
        width: 15%;
        height: 40px;
        margin-right: 20px;
        margin-left: 20px;
    }

    &>select {
        width: 15%;
        height: 40px;
        margin-right: 20px;
        margin-left: 20px;
    }
}

.adjustedVat {
    background-color: #c7d2fe;
    color: black;
    font-weight: 600;
}