.greenLine {
    width: 100%;
    height: 2px;
    background-color: var(--color-theme-primary);
}

.grayLine {
    width: 100%;
    height: 2px;
    background-color: var(--color-gray);
}