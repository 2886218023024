.stepperContainer {
    justify-content: center;
}

.main {
    padding-left: 100px;
    padding-right: 100px;
}

.sectionHeader {
    margin-top: 30px;
    padding: 25px;
}

.invoiceCount {
    display: flex;
    font-weight: 100;
    font-size: 12px;
}

.title {
    font-weight: 600;
    font-size: 25px;
}

.stepWrapper {
    width: 100%;
    padding: 10px;
}