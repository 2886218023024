.input {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    // margin-bottom: 25px;
    justify-content: space-between;
    
}

.field {
    margin-left: 10px;
    font-size: 13px;
    font-family: "Tahoma", sans-serif;
    line-height: 1;
    text-align: center;
    border: 1px solid var(--color-mercury);
    border-radius: 0px;
    width: 12.5%;
    aspect-ratio: 0.999;
    background-color: white;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    &:first-child {
        margin-left: 0;
    }

    &:disabled {
        background-color: white;
        border-color: var(--color-theme-primary);
        color: var(--color-theme-primary);
        color: black;
    }
}



.copyBtn {
    width: 150px;
    font-size: 12px;
    height: 35px;
    border-radius: 0;
    background-color: white;
    font-weight: 600;
}

.container {
    display: flex;
}

@media (min-width: 1300px) {
    .multipleOTPsField {
        width: 11%;
    }
}

@media (min-width: 992px) and (max-width: 1299px) {
    .multipleOTPsField {
        width: 21.5%;
    }

    .copyBtn {
        width: 150px;
        font-size: 8px;
        height: 30px;
    }

    .field {
        border-radius: 0px;
    }
}

@media screen and (max-width:991px) and (min-width:700px) {
    .multipleOTPsField {
        width: 5.5%;
    }
}

@media screen and (max-width: 600px) {
    .copyBtn {
        width: 136px;
        font-size: 7px;
        height: 26px;
    }

    .multipleOTPsField {
        // width: 5.5%;
        border-radius: 0px;
        font-size: 13px;
    }

    .field {
        border-radius: 0px;
    }
}

@media screen and (max-width: 767px) and (min-width: 600px) {
    .field {
        // border-radius: 5px;
        width: 8%;
    }
}


@media screen and (min-width: 991px) and (max-width: 1113px) {
    .copyBtn {
        width: 100%;
    }
}