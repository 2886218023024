.dropdownToggleClassName {
    color: white;
    &:hover {
        color: white;
    }
    &:active{
        color: white;
    }
    &:focus {
        color: white;
    }

}

.changeLang{
    cursor: pointer;
    color: white;
    text-align: end;
    text-decoration: underline;
}