.banner {
    background-size: 2900px 200px;
}

.inner {
    margin-top: 10px !important;
}

.title {
    margin-top: 15px;
}