.container {
    table {
        text-align: center;
        margin: auto;
        width: 100%;
        box-shadow: 0px 0px 10px #00000017;
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;

        &>tbody>tr>td {
            font-weight: 100;
            color: var(--color-table-data-font);
            // text-align: left !important;
        }

        &>thead {
            background-color: #dbdbdb;
        }

        &>thead>tr>th {
            font-weight: 600;
        }

        &>thead>tr>th>div>span {
            font-weight: 600;
        }
    }


}

.statusCell {
    display: flex;
    justify-content: space-between;

    &>span {
        width: 15px;
        border-radius: 50%;
        height: 15px;
        align-self: center;
    }
}

:global(.ar) {
    .container {

        table {
            direction: rtl;

            &>tbody>tr>td {
                text-align: right !important;
            }
        }
    }
}