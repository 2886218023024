.container {
    background-color: #F2F2F2;
    padding: 20px;
}

.block {
    background-color: white;
    padding: 15px;
    margin-top: 30px;
    position: relative;
}

.emptySection {
    background-color: #F2F2F2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.sectionTitle {
    font-weight: 600;
    font-size: 16px;
    margin-top: 35px;
    margin-bottom: 10px;
}

.label {
    font-size: 15px;
    color: var(--color-theme-primary);
}

.value {
    font-size: 13px;
}

.input {
    border-bottom: solid #F2F2F2 1px;
    padding-bottom: 10px;
}

.section {
    display: flex;
    justify-content: space-between;
}

.buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 80px;
    margin-bottom: 60px;
    &>button {
        border-radius: 0;
    }
}