.wrapper {
    display: flex;
    background-color: white;
}

@media (max-width: 991px) {
    .wrapper {
        display: flex;
        background-color: white;
        justify-content: center;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
}